import {FaShippingFast, FaShieldAlt, FaTags, FaCreditCard} from 'react-icons/fa';

const servicesData = [
    {
        id: 1,
        icon: <FaShippingFast />,
        title: "Order Tracking",
        info: "Track your order",
    },
    {
        id: 2,
        icon: <FaShieldAlt />,
        title: "Authentic",
        info: "100% Original products",
    },
    {
        id: 3,
        icon: <FaTags />,
        title: "Curated Products ",
        info: "Products for all needs",
    },
    {
        id: 4,
        icon: <FaCreditCard />,
        title: "Secure Payments",
        info: "SSL / Secure сertificate",
    },
];

export default servicesData;