const productsData = [
    {
        id: 1,
        tag: "hero-product",
        tagline: "Explore Eco-Friendly Products for a Greener You",
        heroImage: "https://res.cloudinary.com/djlc2coam/image/upload/v1715409156/Screenshot_2024-05-11_115550_hvutob.png",
        images: [
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409156/Screenshot_2024-05-11_115550_hvutob.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409156/Screenshot_2024-05-11_115550_hvutob.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409156/Screenshot_2024-05-11_115550_hvutob.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409156/Screenshot_2024-05-11_115550_hvutob.png",
        ],
        title: "Crop Charm Notebook",
        info: "Embrace sustainability with our tree-free notebooks-where every page reflects the minful essence of conscious choices",
        category: "Green Business",
        type: "Eco Conscious",
        
        
        originalPrice: 1499,
        quantity: 1,
        ratings: 1234,
        rateCount: 5,
        path: "/product-details/",
    },
    {
        id: 2,
        tag: "featured-product",
        images: [
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409156/Screenshot_2024-05-11_115532_sxmofz.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409156/Screenshot_2024-05-11_115532_sxmofz.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409156/Screenshot_2024-05-11_115532_sxmofz.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409156/Screenshot_2024-05-11_115532_sxmofz.png"
        ],
        
        title: "Calendar",
        info: "Handcrafted with agri residue paper adorned with jaipur's enchanting block prints. every turn of the page is a step towards sustainable future.",
        category: "Green Business",
        type: "Carbon Neutral",
        
        
        originalPrice: 3990,
        quantity: 1,
        ratings: 1321,
        rateCount: 5,
        path: "/product-details/",
    },
    {
        id: 3,
        tag: "hero-product",
        tagline: "Eco-Friendly Finds That Make You Smile",
        heroImage: "https://res.cloudinary.com/djlc2coam/image/upload/v1715409157/Screenshot_2024-05-11_120001_wjxmum.png",
        images: [
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409157/Screenshot_2024-05-11_120001_wjxmum.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409157/Screenshot_2024-05-11_120001_wjxmum.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409157/Screenshot_2024-05-11_120001_wjxmum.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409157/Screenshot_2024-05-11_120001_wjxmum.png",
        ],
        
        title: "Harvest Bags-Impactful",
        info: "Messages that matter-our Impactful Bags make your statements heard in style",
        category: "Eco Essentials",
        type: "Green Materials",
        
        
        originalPrice: 2990,
        quantity: 1,
        ratings: 144,
        rateCount: 5,
        path: "/product-details/",
    },
    {
        id: 4,
        images: [
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409157/Screenshot_2024-05-11_115752_voblgh.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409157/Screenshot_2024-05-11_115752_voblgh.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409157/Screenshot_2024-05-11_115752_voblgh.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409157/Screenshot_2024-05-11_115752_voblgh.png",
        ],
        
        title: "Harvest bag-Minimal",
        info: "Elegance in its purest form-our minimalistic bags add a touch of refined simplicity to any occasion",
        category: "Eco Essentials",
        type: "Green Materials",
       
                originalPrice: 999,
        quantity: 1,
        ratings: 556,
        rateCount: 4,
        path: "/product-details/",
    },
    {
        id: 5,
        tag: "featured-product",
        images: [
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409156/Screenshot_2024-05-11_115615_xa2aom.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409156/Screenshot_2024-05-11_115615_xa2aom.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409156/Screenshot_2024-05-11_115615_xa2aom.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409156/Screenshot_2024-05-11_115615_xa2aom.png",
        ],
        
        title: "Zen in a Box",
        info: "Discover daily calm with our mindfulness cards-simple pompts for mindful pauses,bringing peace to each moment.",
        category: "Green Business",
        type: "Carbon Neutral",
       
        
        originalPrice: 2990,
        quantity: 1,
        ratings: 563,
        rateCount: 5,
        path: "/product-details/",
    },
    {
        id: 6,
        images: [
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409156/Screenshot_2024-05-11_115731_ccsbyp.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409156/Screenshot_2024-05-11_115731_ccsbyp.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409156/Screenshot_2024-05-11_115731_ccsbyp.png",
            "hhttps://res.cloudinary.com/djlc2coam/image/upload/v1715409156/Screenshot_2024-05-11_115731_ccsbyp.png",
        ],
                title: "Harvest bags- Artistic",
        info: "Artistry in every carry -our artistic bags elevate any moment with creative elegance",
        category: "Eco Essentials",
        type: "Green Materials",
        
        
        originalPrice: 599,
        quantity: 1,
        ratings: 836,
        rateCount: 4,
        path: "/product-details/",
    },
    {
        id: 7,
        tag: "hero-product",
        tagline: "Reduce Your Footprint, Start Shopping Green ",
        heroImage: "https://res.cloudinary.com/djlc2coam/image/upload/v1715409157/Screenshot_2024-05-11_120032_kjtfg4.png",
        images: [
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409157/Screenshot_2024-05-11_120032_kjtfg4.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409157/Screenshot_2024-05-11_120032_kjtfg4.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409157/Screenshot_2024-05-11_120032_kjtfg4.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409157/Screenshot_2024-05-11_120032_kjtfg4.png",
        ],
        
        title: "%100 Crop Residue based Hand made paper (150-200 GSM)",
        info: "Discover the perfect balance of durability and elegance with our premium 150-200 gsm paper collect",
        category: "Eco Supplies",
        type: "Eco Conscious",
        
        
        originalPrice: 1990,
        quantity: 1,
        ratings: 679,
        rateCount: 4,
        path: "/product-details/",
    },
    {
        id: 8,
        tag: "featured-product",
        images: [
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409156/Screenshot_2024-05-11_115633_dfa1ea.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409156/Screenshot_2024-05-11_115633_dfa1ea.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409156/Screenshot_2024-05-11_115633_dfa1ea.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409156/Screenshot_2024-05-11_115633_dfa1ea.png",
        ],
                title: "Santarams Doll Keychain",
        info: "Carry a touch of rajasthan's heritage wherever you go with these delightful and intricately designed key companions",
        category: "Green Business",
        type: "Eco Conscious",
        
        
        originalPrice: 7999,
        quantity: 1,
        ratings: 755,
        rateCount: 4,
        path: "/product-details/",
    },
    {
        id: 9,
        images: [
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409157/Screenshot_2024-05-11_120142_zbxhdi.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409157/Screenshot_2024-05-11_120142_zbxhdi.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409157/Screenshot_2024-05-11_120142_zbxhdi.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409157/Screenshot_2024-05-11_120142_zbxhdi.png",
        ],
                title: "%100 Crop Residue based Hand made paper (250-300 GSM)",
        info: "Elevate your packaging game with our durable and versatile 200-250 gsm paper,crafted fro strength and durability.",
        category: "Eco Supplies",
        type: "Green Materials",
        
        
        originalPrice: 599,
        quantity: 1,
        ratings: 836,
        rateCount: 4,
        path: "/product-details/",
    },

    {
        id: 10,
        images: [
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409157/Screenshot_2024-05-11_120051_wmp284.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409157/Screenshot_2024-05-11_120051_wmp284.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409157/Screenshot_2024-05-11_120051_wmp284.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409157/Screenshot_2024-05-11_120051_wmp284.png",
        ],
                title: "%100 Crop Residue pulp",
        info: "Use 100% Agri pulp to make top quality papers and wide range of molded papers.",
        category: "Sustainable Agriculture",
        type: "Green Materials",
        
        
        originalPrice: 599,
        quantity: 1,
        ratings: 836,
        rateCount: 4,
        path: "/product-details/",
    },

    {
        id: 11,
        images: [
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409156/Screenshot_2024-05-11_115357_esix4w.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409156/Screenshot_2024-05-11_115357_esix4w.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409156/Screenshot_2024-05-11_115357_esix4w.png",
            "https://res.cloudinary.com/djlc2coam/image/upload/v1715409156/Screenshot_2024-05-11_115357_esix4w.png",
        ],
                title: "E0 FuMa Boards",
        info: "100% crop residue based E0 certified boards.",
        category: "Sustainable Agriculture",
        type: "Green Materials",
        
        
        originalPrice: 599,
        quantity: 1,
        ratings: 836,
        rateCount: 4,
        path: "/product-details/",
    },
];

export default productsData;