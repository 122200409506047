import React, { useContext, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import commonContext from '../../contexts/common/commonContext';
import useForm from '../../hooks/useForm';
import useOutsideClose from '../../hooks/useOutsideClose';
import useScrollDisable from '../../hooks/useScrollDisable';
import { signup, login, signInWithGoogle } from '../../services/auth';

const AccountForm = () => {
    const { isFormOpen, toggleForm } = useContext(commonContext);
    const { inputValues, handleInputValues, handleFormSubmit: handleFormSubmitFromHook } = useForm();
    const formRef = useRef();
    useOutsideClose(formRef, () => { toggleForm(false); });
    useScrollDisable(isFormOpen);
    const [isSignupVisible, setIsSignupVisible] = useState(false);
    const [error, setError] = useState(null);

    const handleIsSignupVisible = () => {
        setIsSignupVisible(prevState => !prevState);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const { mail, password, conf_password } = inputValues;

        if (isSignupVisible) {
            if (password !== conf_password) {
                setError("Passwords do not match");
                return;
            }
            try {
                await signup(mail, password);
                // Handle post-signup actions
            } catch (error) {
                setError(error.message);
            }
        } else {
            try {
                await login(mail, password);
                // Handle post-login actions
            } catch (error) {
                setError(error.message);
            }
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            await signInWithGoogle();
            // Handle post-Google sign-in actions
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <>
            {isFormOpen && (
                <div className="backdrop">
                    <div className="modal_centered">
                        <form id="account_form" ref={formRef} onSubmit={handleFormSubmit}>
                            {/*===== Form-Header =====*/}
                            <div className="form_head">
                                <h2>{isSignupVisible ? 'Signup' : 'Login'}</h2>
                                <p>
                                    {isSignupVisible ? 'Already have an account?' : 'New to orelse?'}
                                    &nbsp;&nbsp;
                                    <button type="button" onClick={handleIsSignupVisible}>
                                        {isSignupVisible ? 'Login' : 'Create an account'}
                                    </button>
                                </p>
                            </div>
                            {/*===== Form-Body =====*/}
                            <div className="form_body">
                                {error && <p className="error">{error}</p>}
                                {isSignupVisible && (
                                    <div className="input_box">
                                        <input
                                            type="text"
                                            name="username"
                                            className="input_field"
                                            value={inputValues.username || ''}
                                            onChange={handleInputValues}
                                            required
                                        />
                                        <label className="input_label">Username</label>
                                    </div>
                                )}
                                <div className="input_box">
                                    <input
                                        type="email"
                                        name="mail"
                                        className="input_field"
                                        value={inputValues.mail || ''}
                                        onChange={handleInputValues}
                                        required
                                    />
                                    <label className="input_label">Email</label>
                                </div>
                                <div className="input_box">
                                    <input
                                        type="password"
                                        name="password"
                                        className="input_field"
                                        value={inputValues.password || ''}
                                        onChange={handleInputValues}
                                        required
                                    />
                                    <label className="input_label">Password</label>
                                </div>
                                {isSignupVisible && (
                                    <div className="input_box">
                                        <input
                                            type="password"
                                            name="conf_password"
                                            className="input_field"
                                            value={inputValues.conf_password || ''}
                                            onChange={handleInputValues}
                                            required
                                        />
                                        <label className="input_label">Confirm Password</label>
                                    </div>
                                )}
                                <button type="submit" className="btn login_btn">
                                    {isSignupVisible ? 'Signup' : 'Login'}
                                </button>
                            </div>
                            {/*===== Form-Footer =====*/}
                            <div className="form_foot">
                                <p>or login with</p>
                                <div className="login_options">
                                    <button type="button" onClick={handleGoogleSignIn}>
                                        Google
                                    </button>
                                </div>
                            </div>
                            {/*===== Form-Close-Btn =====*/}
                            <div className="close_btn" title="Close" onClick={() => toggleForm(false)}>
                                &times;
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
};

export default AccountForm;
