const reviewsData = [
    {
        id: 1,
        name: "xyz",
        date: "4 Aug 2023",
        review: "xyz review.",
        rateCount: 3,
    },
    {
        id: 2,
        name: "zxy",
        date: "15 July 2023",
        review: "Very good ",
        rateCount: 5,
    },
   
]

export default reviewsData;