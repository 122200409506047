import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAnFimRWxh7kTFKYYDyNGq9VuCOb0DQQeE",
  authDomain: "ecommercemarketplace-6b770.firebaseapp.com",
  projectId: "ecommercemarketplace-6b770",
  storageBucket: "ecommercemarketplace-6b770.appspot.com",
  messagingSenderId: "254233809822",
  appId: "1:254233809822:web:88456b53cbfc0c52a57c8f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };